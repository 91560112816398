import React from 'react'


export const About = () => {
  return (
    <>
    <div className="diver">
      <h1>About page</h1></div></>
  )
}
